"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";

const Section3 = () => {
  const { t } = useTranslation();

  return (
    <div className="sec-3 mx-auto max-w-6xl px-4 py-[60px]">
      <div className="grid grid-cols-12 gap-y-14 md:gap-x-14">
        <div className="md:col-span-6 col-span-12 relative md:order-1 order-2">
          <div className="min-h-[380px] bg-gray-100">
            <Image
              alt="Car insurance image"
              loading="lazy"
              width="560"
              height="420"
              className="h-[224px] rounded-xl object-cover md:h-[420px]"
              src="/images/how_it_works.jpg"
            />
          </div>
        </div>
        <div className="md:col-span-6 col-span-12 md:order-2 order-1">
          <h2 className="mb-[14px] text-[32px] font-bold leading-9">
            {t("Home.Section3.key1")}
          </h2>

          <p className="mb-6 text-base">
            {t("Home.Section3.key2")}
            <span className="font-semibold">{t("Home.Section3.key3")}</span>,
            {t("Home.Section3.key4")}
            <span className="font-semibold">{t("Home.Section3.key5")}</span>
          </p>

          <div className="flex flex-col gap-[22px]">
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-md theme-bg-color text-white">
                <span className="">1</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key6")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-md theme-bg-color text-white">
                <span className="">2</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key7")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-md theme-bg-color text-white">
                <span className="">3</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key8")}
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <div className="grid h-11 w-11 flex-shrink-0 place-items-center rounded-md theme-bg-color text-white">
                <span className="">4</span>
              </div>
              <span className="text-base font-medium">
                {t("Home.Section3.key9")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
